import * as React from "react"
import { navigate } from 'gatsby';
import { Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import mainBanner from '../images/main-banner.svg';
import smartMeterIcon from '../images/smart-meter-icon.svg';
import smartMeterCadIcon from '../images/smart-meter-cad-icon.svg';
import voltawareSensorIcon from '../images/voltaware-sensor-icon.svg';

import Layout from '../components/Layout';
import { Wrapper, Text, Button } from '../mui-elements/shared';
import { SEO } from '../components/SEO';

import {
  SectionGreen,
  SectionGreenContent,
  SectionWhite,
  Integration,
} from '../style/pages/index';

export default function HomePage() {
  return (
    <Layout>
      <Wrapper maxWidth={false} disableGutters>
        <SectionGreen>
          <img src={mainBanner} alt="" />
          <SectionGreenContent>
            <Typography component="h1" variant="h1">
              Enhance your apps through the Voltaware Platform
            </Typography>
            <ul>
              <li>
                <ArrowRightIcon fontSize="large" />
                <Text>Flexible endpoints</Text>
              </li>
              <li>
                <ArrowRightIcon fontSize="large" />
                <Text>Detailed guides</Text>
              </li>
              <li>
                <ArrowRightIcon fontSize="large" />
                <Text>Developer-focused clear documentation</Text>
              </li>
            </ul>
            <Text>
              We’ve put together some handy guides and reference material to 
              get you started.
            </Text>
          </SectionGreenContent>
        </SectionGreen>
        <SectionWhite>
          <Typography component="h1" variant="h1">
            3 Ways to Integrate
          </Typography>
          <div>
            <Integration>
              <img src={smartMeterIcon} alt="Smart Meter Integration" />
              <Typography component="h2" variant="h2">
                Smart Meters
              </Typography>
              <div>
                <Text>
                  Smart Meter data (15 or 30 minutes consumption 
                  reading intervals)
                </Text>
                <ul>
                  <li>
                    <Text>Smart Meter API</Text>
                  </li>
                </ul>
              </div>
              <Button
                fullWidth={true}
                variant="contained"
                role="link"
                onClick={()=>{navigate("/integrations/smart-meters")}}
              >
                More Info
              </Button>
            </Integration>
            <Integration>
              <img src={smartMeterCadIcon} alt="Smart Meter and CAD Integration" />
              <Typography component="h2" variant="h2">
                Smart Meters + CAD
              </Typography>
              <div>
                <Text>
                  High Resolution Data {'(less than or equal to 10 seconds reading intervals)'}
                </Text>
                <ul>
                  <li>
                    <Text>RESTful API</Text>
                  </li>
                </ul>
              </div>
              <Button
                fullWidth={true}
                variant="contained"
                role="link"
                onClick={()=>{navigate("/integrations/smart-meters-cad")}}
              >
                More Info
              </Button>
            </Integration>
            <Integration>
              <img src={voltawareSensorIcon} alt="Voltaware Sensor Integration" />
              <Typography component="h2" variant="h2">
                Voltaware Sensors
              </Typography>
              <div>
                <Text>
                  Ultra-High Resolution Data (event-based readings)
                </Text>
                <ul>
                  <li>
                    <Text>RESTful API</Text>
                  </li>
                  <li>
                    <Text>AMPQ (Messaging Bus)</Text>
                  </li>
                </ul>
              </div>
              <Button
                fullWidth={true}
                variant="contained"
                role="link"
                onClick={()=>{navigate("/integrations/voltaware-sensors")}}
              >
                More Info
              </Button>
            </Integration>
          </div>
        </SectionWhite>
      </Wrapper>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)
