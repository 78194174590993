import styled from 'styled-components';

export const SectionGreen = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary_opaque};
  padding-top: 8rem;
  margin-bottom: 1rem;

  * {
    transition: all .33s;
  }

  img {
    user-select: none;
    width: 100%;

    @media (min-width: 1536px) {
      max-width: 1100px;
    }

    @media (max-width: 1536px) {
      max-width: 864px;
    }

    @media (max-width: 1200px) {
      max-width: 800px;
    }
  }

  @media (max-width: 900px) {
    padding-top: 4rem;
  }
`;

export const SectionGreenContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  right: 0;
  top: 5rem;
  padding: 0 1rem;

  h1 {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 1rem;
  }

  p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.5rem;
  }

  ul {
    padding: 0;
    margin: 0;
    margin-bottom: 1.5rem;
    list-style: none;

    li {
      display: flex;
      align-items: center;

      p {
        line-height: 1.5;
        font-weight: 500;
      }

      svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  @media (max-width: 1200px) {
    max-width: 42%;

    ul {
      margin-bottom: 0.5rem;
    }

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1.3rem;
    }
  }

  @media (max-width: 900px) {
    position: relative;
    top: 0;
    right: 0;
    padding: 1rem;
    max-width: unset;
  
    p {
      font-size: 1.5rem;
    }
  }
`;

// Section II
export const SectionWhite = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 2rem 0 1rem;

  h1 {
    margin: 2rem 0 4rem;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (max-width: 600px) {
    margin-top: 0;

    h1 {
      font-size: 2.2rem;
    }
  }
`;

export const Integration = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  margin: 0 2rem;
  margin-bottom: 1.5rem;

  img {
    max-width: 150px;
  }

  h2 {
    margin: 1.5rem 0;
  }

  & > div {
    ul {
      align-self: flex-start;
      padding: 0;

      li {
        position: relative;
        list-style: none;
        color: ${({ theme }) => theme.colors.text};

        p {
          padding-left: 1.2rem;
          font-weight: 500;
        }

        &::before {
          content: ' ';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors.primary};
          top: 45%;
          transform: translateY(-45%);
        }
      }
    }

    @media (min-width: 1200px) {
      & > p {
        height: 6.5rem;
      }

      ul {
        height: 5rem;
        margin: 0;
      }
    }
  }
`;
